@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(246, 246, 249);
  color: #51545D;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sliding-menu-right-master {
  visibility: hidden;
}

.sliding-menu-right-master.show{
  visibility: visible;
}

.sliding-menu-right {
  transform: translateX(101%);
  opacity: 0;
  transition: transform .3s ease-out, height .3s ease-in-out, opacity .3s ease-in-out;
}

.sliding-menu-right.show{
  transform: translateX(0);
  opacity: 1;
}

.css-13cymwt-control{
  transition: none !important;
  -webkit-transition: none !important;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
 .dot-flashing {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: white;
  color: white;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -18px;
  width: 12px;
  height: 12px;
  border-radius: 8px;
  background-color: white;
  color: white;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 18px;
  width: 12px;
  height: 12px;
  border-radius: 8px;
  background-color: white;
  color: white;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: white;
  }
  50%, 100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

.react-tel-input{
  font-family: 'Raleway', sans-serif !important;
  font-family: 1rem !important;
}

.loading-state{
  cursor: none !important;
  pointer-events: none !important;
}

.text-ellipsis{
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.react-datepicker-wrapper{
  width: 100%;
}